@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap');

.arabic {
    text-align: right;
    font-family: "Tajawal", sans-serif;
}

.english {
    text-align: left;
    font-family: "Poppins", sans-serif;
}

.mission-container {
  margin-top: 50px;
  margin-bottom: 84px;
  background:  linear-gradient(101deg, #01C0BE 0%, #044FB0 51.4%);
}

.mission-card-section {
  display: flex;
  justify-content: space-between;
  padding: 60px 0;
}

.mission-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  padding: 10px 30px;
}

.mission-card:hover {
  transform: scale(1.05);
}

.mission-card-border {
  height: 5px;
}

/* .border-1 {
  background-color: var(--card-border-1);
}

.border-2 {
  background-color: var(--card-border-2);
}

.border-3 {
  background-color: var(--card-border-3);
} */

.mission-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.mission-card-image {
  margin-bottom: 20px;
  text-align: center;
}

.mission-card-image img {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  display: block;
  background: rgba(255, 255, 255, 0.08);
  padding: 15px;
  border-radius: 15px;
}

.mission-card-title {
  color: #FFF;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 25px;
}

.mission-card-description {
  color: #FFF;
  font-size: 21px;
  font-weight: 400;
  line-height: 117.852%;
}

@media (max-width: 992px) {
  .mission-card-section {
    flex-direction: column;
    align-items: center;
  }

  .mission-card {
    width: 70%;
    margin-bottom: 20px;
  }
  .mission-card-description {
    font-size: 20px;
    padding: 10px 50px;
    margin: auto;
  }
  .mission-card-title {
    font-size: 28px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .mission-card {
    width: 80%;
    padding: 10px 0;
  }
  .mission-card-section {
    padding: 50px 0;
  }

  .mission-card-txt {
    width: 85%;
    font-size: 18px;
  }
  .mission-card-title {
    font-size: 24px;
  }

  .mission-card-txt {
    font-size: 16px;
  }

  .mission-card-description {
    font-size: 18px;
  }
}

@media (max-width: 552px) {
  .mission-card {
    width: 95%;
  }

  .mission-card-title {
    font-size: 24px;
  }

  .mission-card-description {
    font-size: 16px;
  }

  .mission-card-image img {
    width: 65px;
    height: 65px;
  }
}

@media (max-width: 400px) {
  .mission-card-description {
    padding: 10px 22px;
  }
}
