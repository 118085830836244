@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap');


.arabic {
  text-align: right;
  font-family: "Tajawal", sans-serif;
}

.english {
  text-align: left;
  font-family: "Poppins", sans-serif;
}

.PricingApp .pricing-cards {
  margin: 4em 0;
  min-height: 36rem;
  display: flex;
  justify-content: center;
  align-items: center;
}


.PricingCard:nth-child(2) {
  z-index: 99999; 
  transform: scale(1.05); 
}

.PricingCard:hover {
  transform: scale(1.1); 
}

.PricingCard:nth-child(2) .card-btn {
  background: #044FB0;
  color: white;
}

.PricingCard:nth-child(2) .card-btn:is(:focus-visible, :hover) {
  border-radius: 0.5em;
  border: 2px solid transparent;
  color: white;
}

.PricingCard .card-btn {
  cursor: pointer;
  padding-block: 1em;
  width: 100%;
  outline: transparent;
  transition: 0.125s ease;
  color: #044FB0;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 12px;
  border: 1px solid  #044FB0;
  background:  #FFF;
  margin-bottom: 15px;

}


.PricingCard .card-features h3 {
  /* color: #1B1E28; */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}



.PricingCard .card-btn:is(:focus-visible, :hover) {
  background: linear-gradient(white, #044FB0) padding-box,
    linear-gradient(to right, var(--primary-linear-gradient)) border-box;
  border-radius: 0.5em;
}

.PricingCard:nth-child(2) .card-btn {
  background: transparent;
  border: 1.112px solid  #044FB0;
  background: #044FB0;
  color: white;
}

.PricingCard:nth-child(2) .card-btn:is(:focus-visible, :hover) {
  border-radius: 0.5em;
  border: 2px solid transparent;
  /* color: white; */
}

.blue-icon {
  color: #044FB0;
}

.red-icon {
  color: #B90101;
}



.PricingCard.english {
  text-align: left; 
}

.PricingCard .card-feature {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* for Arabic */
  /* color: #575757; */
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.PricingCard.english .card-feature {
  justify-content: flex-start; 
  font-size: 13px;
}

.PricingCard .feature-icon {
  margin-left: 0.5em;
}

.PricingCard.english .feature-icon {
  margin-right: 0.5em;
  margin-left: 0;
}



@media (max-width: 768px) {
  .PricingCard:nth-child(2) {
    align-self: center;
  }
  .PricingCard:nth-child(2),
  .PricingCard {
    z-index: 99999; 
    transform: scale(1.05); 
  }
  .PricingCard .card-feature {
    font-size: 16px;
    
    }
}


@media (max-width: 400px) {
  .PricingCard .card-feature {
    font-size: 14px;
  }
  .PricingCard .card-btn {
    padding-block: 15px 20px;
    margin: 0 auto 12px auto;
    width: 100%;
  }
  .PricingCard .card-features h3 {
    font-size: 18px;
    margin: 10px 0;
  }
}
@media (max-width: 390px) {
  .PricingCard .card-feature {
    font-size: 14px;
  }
}
@media (max-width: 356px) {
  .PricingCard .card-feature {
    font-size: 12px;
  }
}
@media (max-width: 330px) {
  .PricingCard .card-feature {
    font-size: 12px;
  }
}

