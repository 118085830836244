@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap');



/* .card-background-dark{
    background: gray;
} */
.arabic {
  text-align: right;
  font-family: "Tajawal", sans-serif;
}

.english {
  text-align: left;
  font-family: "Poppins", sans-serif;
}

.cards-container {
    margin-top: 50px;
}

/* .quote-container .quotes {
    font-size: 30px;
    margin: 20px 0;
    background: linear-gradient(90deg, #01C0BE 29.63%, #044FB0 67.65%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} */


.quote-container {
    display: flex;
    align-items: center;
    justify-content: flex-end; 
}
.quote-container img {
    margin: 15px 0;
    width: 35px;
    /* padding: 10px; */
}

 /* .special-txt {
    background: linear-gradient(90deg, #01C0BE 29.63%, #044FB0 67.65%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 54px;
    font-style: normal;
    font-weight: 600;
    line-height: 65.818px;
} */

.cards-container .review-title-txt {
    color: #868D94;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
    margin-bottom: 40px;
}

.cards-container .title-customers .customers {
    /* color: #000; */
    color: var(--text-color);

    text-align: center;
    font-size: 54px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.all-customers .card-users {
    border-radius: 12px;
    text-align: right;
    padding:  10px 20px;
    /* border: 1px solid #ddd;
    background: #FFF; */
    width: 90% !important;
    height: 230px;
    /* height: auto; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 15px 5px;
}

.card-users .comment-reviewer {
    letter-spacing: 0.16px;

    /* color: #12141D; */
    text-align: right;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 125% */
    white-space: pre-line;

}

.card-users .line {
    display: block;
    height: 3px;
    width: 20%;
    margin: 20px 0;
    background: linear-gradient(90deg, #0CE4C5 0%, #044FB0 93.75%);
    margin-left: auto;
}

.user-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.user-info .user-data {
    display: flex;
    flex-direction: column;
    align-items: flex-end; 
}

.all-customers .card-users .user-image {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-left: 15px; 
}

.all-customers .card-users .users-name {
    /* color: #000; */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

@media(max-width: 768px) {
    .cards-container .title-customers .customers {
        font-size: 34px;
    }
    .all-customers .card-users {
        width: 95% !important;
    }
    .all-customers .card-users {
        height: auto;
    }
}


@media(max-width: 400px) {
    .card-users p {
        font-size: 12px;
    }

    .all-customers .card-users .user-image {
        width: 40px;
        height: 40px;
    }

    .all-customers .card-users .users-name {
        font-size: 14px;
    }
    .cards-container .review-title-txt {
        margin-bottom: 14px;
    }
    .marginTop{
        margin-top: 80px;
    }
}


@media(max-width: 330px) {
    .cards-container .title-customers .customers {
        font-size: 28px;
    }
    .cards-container .review-title-txt {
        font-size: 18px;
    }
}
