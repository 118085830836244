@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap');

.arabic {
    text-align: right;
    font-family: "Tajawal", sans-serif;
}

.english {
    text-align: left;
    font-family: "Poppins", sans-serif;
}

.shop_container {
    align-items: center;
    justify-content: center;

}

.shop_cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px 0;
}

.shop_cont.column {
    flex-direction: column;
    align-items: center;
}

.shop_cont .text-section {
    flex: 1;
    padding: 20px;
}



.shop_cont .text-section .text-buyer {
    /* color: #000; */
    color: var(--text-color);
    font-size: 45px;
    font-weight: 600;
    line-height: 113.5%;
    text-transform: capitalize;
}

.shop_cont .text-section .text-shops {
    color: #868D94;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 20px;
}

.cta {
    display: flex;
    gap: 10px;
    padding-top: 25px;
}

.cta-button1,
.cta-button2 {
    cursor: pointer;
    border: none;
    border-radius: 8px;
    background: #044FB0;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.25);
    width: 130px;
    height: auto;
}

.logo-img-shop {
    width: 13%;
    height: auto;
    margin-bottom: 20px;
}

.arabic .cta {
    flex-direction: row-reverse;
}

.arabic .shop_cont {
    flex-direction: row-reverse;
}

.shop_cont .text-section .text-buyer.english,
.text-shops.english {
    text-align: left;
}

.shop_cont .text-section .text-buyer.arabic,
.text-shops.arabic {
    text-align: right;
}


/* global */
.top-text{
    margin: 90px 0;
}



.shop_cont .image-section img {
    width: 75%;
    max-width: 500px;
    height: auto;
    margin: 0;
    display: block;
    border-radius: 8px;
}


.shop_cont .image-section {
    flex: 1;
    padding: 20px;
    display: flex;
}


.arabic .shop_cont .image-section {
    justify-content: flex-end;
}

.english .shop_cont .image-section {
    justify-content: flex-start;
}
.arabic .shop_cont .special-img {
    justify-content: flex-start;
}

.english .shop_cont .special-img {
    justify-content: flex-end;
}



@media (max-width: 768px) {
    .shop_cont .image-section img {
        width: 75%;
        max-width: 400px;
        height: auto;
        margin: 15px auto;
        display: block;
        align-items: center;
        border-radius: 8px;
        justify-content: center;
    }

    .arabic .shop_cont .image-section img {
        margin-right: 0;
        margin-left: auto;
        justify-content: center;
        align-items: center;
        display: block;
    }

    .english .shop_cont .image-section img {
        margin-left: 0;
        margin-right: auto;
        justify-content: center;
        align-items: center;
        display: block;
    }
}
/*  */

@media (max-width: 992px) {
    .shop_cont.column {
        flex-direction: column;
        margin-bottom: 100px;
    }

    .cta-button1,
    .cta-button2 {
        margin: 0;
        width: 150px;
    }

    .logo-img-shop {
        width: 25%;
    }
    .shop_cont .text-section .text-buyer {
        font-size: 38px;
    }
    .shop_cont .text-section .text-shops {
        font-size: 18px;
    }
}


@media (max-width: 768px) {
    .shop_cont.column {
        flex-direction: column;
        margin-bottom: 100px;
    }

    .cta-button1,
    .cta-button2 {
        margin: 0;
        width: 150px;
    }

    .logo-img-shop {
        width: 40%;
    }

    .arabic .shop_cont.column .first .text-buyer {
        align-items: flex-end;
        text-align: center;
        margin-bottom: 25px;
        /* color: #000; */
        font-size: 28px;
        font-weight: 600;
        line-height: 113.5%;
        text-transform: capitalize;

    }
    .arabic .shop_cont.column .text-shops,
    .english .shop_cont.column .text-shops  {
        align-items: flex-end;
        text-align: center;
        font-size: 18px;
        margin: 0 25px;

    }
    .arabic .shop_cont.column .first .logo-cta,
    .english .shop_cont.column .first .logo-cta{
        /* align-items: flex-end;
        text-align: right; */
        display: none;
    }

    .english .shop_cont.column  .first .text-buyer {
        align-items: flex-start;
        text-align: center;
        margin-bottom: 25px;
        /* color: #000; */
        font-size: 30px;
        font-weight: 600;
        line-height: 113.5%;
        text-transform: capitalize;

    }

    .shop_cont .image-section {
        display: flex;
        justify-content: center;
        align-items: center;
    }

  

    .arabic .shop_cont .image-section img,
    .english .shop_cont .image-section img {
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        align-items: center;
        display: block;
    }

    .shop_cont .image-section img {
        width: 75%;
        max-width: 400px;
        height: auto;
        margin: 15px auto;
        display: block;
        border-radius: 8px;
    }



}

@media (max-width: 610px) {
    .shop_cont .text-buyer {
        font-size: 30px;
    }
    .shop_cont .text-shops {
        font-size: 15px;
    }

}

@media (max-width: 400px) {
    .cta-button1,
    .cta-button2 {
        margin: 0;
        width: 130px;
    }
}



@media (max-width: 330px) {
    
    .arabic .shop_cont.column  .first .text-buyer {
        font-size: 22px;
    }

    .english .shop_cont.column  .first .text-buyer {
        font-size: 22px;
    }

    .arabic .shop_cont.column .text-shops{
        font-size: 16px;
        margin: 10px 12px;
    }

    .english .shop_cont.column .text-shops  {
        font-size: 14px;
        margin: 10px;

    }
}