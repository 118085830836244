@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap');


.background-light{
  background: linear-gradient(101deg, #01C0BE 0%, #044FB0 51.4%) !important;
}

.background-dark{
  background: black !important;
}


.arabic {
  text-align: right;
  font-family: "Tajawal", sans-serif;
}

.english {
  text-align: left;
  font-family: "Poppins", sans-serif;
}

.payment-container.english {
  direction: rtl;
  text-align: left;
  font-family: "Poppins", sans-serif;
}


.payment-container.arabic {
  direction: ltr;
  text-align: right;
  font-family: "Tajawal", sans-serif;
}

.payment-container {

  padding: 80px 0;
  width: 100%;
  /* background:var(--gradient-background); */
  /* background: linear-gradient(101deg, #01C0BE 0%, #044FB0 51.4%); */
  /* background: var(--gradient-background); */
  margin-bottom: 140px;
}

.payment-flex {
  display: flex;
  justify-content: space-between;
  /* margin: 25px 0; */
}

.left-div {
  display: flex;
  flex-direction: column;
  flex: 1;
}


.image-row {
  display: flex;
  /* justify-content: space-between;  */
  margin-bottom: 10px;
}

.image-row img {
  width: 115px;
  height: 110px; 
  background-color: #F5F5F5;
  border-radius: 15px;
  padding: 15px;
  margin-right: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  object-fit: contain; }

.image-row img:hover {
  transform: scale(1.05);
}

@media (max-width: 992px) {
  .image-row img {
    width: 22%; 
    height: auto;
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .image-row img {
    width: 22%; 
    height: auto;
    margin: 0 auto;
  }
}

@media (max-width: 368px) {
  .image-row img {
    width: 28%; 
  }
}



.bottom {
  margin-bottom: 45px;

}

.right-div {
  flex: 1;
  align-items: center;
  margin: 15px 0;

  /* min-width: 300px; */
}

.right-div h2 {
  color: #FFF;
  /* color: var(--text-color); */
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
}

.right-div p {
  color: #F5F5F5;
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: 117.852%;
}

@media (max-width: 992px) {
  .right-div h2 {
    font-size: 34px;
    margin-bottom: 15px;
    /* text-align: center; */
  }

  .right-div p {

    font-size: 22px;
    /* text-align: center; */
  }
}

@media (max-width: 768px) {
  .payment-flex {
    flex-direction: column-reverse;
    align-items: center;
  }

  .left-div {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .right-div {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .image-row img {
    width: 24%;
    margin: 0 auto;

  }

  .right-div h2 {
    font-size: 40px;
    margin-bottom: 15px;
    text-align: center;
  }

  .right-div p {

    font-size: 18px;
    text-align: center;
    margin: 0 55px 20px 55px;
  }
}

@media (max-width: 560px) {
  .right-div h2 {
    font-size: 28px;

  }
}

@media (max-width: 410px) {
  .right-div h2 {
    font-size: 26px;
  }

  .right-div p {
    font-size: 15px;
    margin: 0 0 15px 0;
  }
}

@media (max-width: 360px) {
  .right-div h2 {
    font-size: 26px;
  }


  .right-div.arabic p {
    font-size: 14px;
    margin: 0;
    margin-bottom: 15px;
  }


  .right-div.english p {
    font-size: 14px;
    margin: 0;
    margin-bottom: 15px;
  }

  .image-row.arabic img {
    width: 28%;

  }

  .image-row.english img {
    width: 28%;

  }
}