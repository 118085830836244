@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap');

.arabic {
    text-align: right;
    font-family: "Tajawal", sans-serif;
}

.english {
    text-align: left;
    font-family: "Poppins", sans-serif;
}

.parent-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95vh;
}
.success-page-container {
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    max-width: 400px;
    border-radius: 25px;
    border: 3px solid #FFF;
    background: #FFF;
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.25);
  }
  


  
  .title {
    color: #044FB0;
    font-size: 24px;
    margin-top: 20px;
    text-align: center;

  }
  
  .text {
    color: #666;
    font-size: 16px;
    margin: 10px auto;
    text-align: center;

  }
  
  .options-container {
    display: flex;
    gap: 10px;
    /* justify-content: space-between; */
    width: 100%;
    margin: 20px auto;
    text-align: center;
  }
  
  .home-button {
    color: #e7ebef;
    background-color: #044FB0;
    width: 100%;
    margin-top: 30px;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

  }
  


  @media (max-width: 768px) {
    .success-page-container,
    .parent-container  {
      max-width: 95%;
      margin: 0 auto;
    }
    .title{
      font-size: 20px;
    }
  }