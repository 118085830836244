@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap');

.arabic {
    text-align: right;
    font-family: "Tajawal", sans-serif;
}

.english {
    text-align: left;
    font-family: "Poppins", sans-serif;
}

.frq-main-container {
    padding: 2em 0;
    margin-top: 150px;
    margin-bottom: 90px;
}


header .questions {
    text-align: center;
    /* color: #000; */
    color: var(--text-color);

    font-size: 54px;
    font-style: normal;
    font-weight: 600;
    line-height: 56.491px;
    letter-spacing: -2.317px;
}


.sectionFourTxt {
    color: #868D94;
    font-size: 26px;
    margin: 0 auto;

    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.5px;
    padding-top: 15px;


}

.accordionBlock {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 26px;
    justify-content: center;
}


.question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em 0;
    margin: 0;
    border-bottom: 1px solid #B0B5B9;
}

.last-question .question {
    border-bottom: none;
}


/* Remove the border when the answer is open */
.questions-container.open .question {
    border-bottom: none;
}



.question h2 {
    /* color: #000; */
    color: var(--text-color);

    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
}

.answers p {
    color: #868D94;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.5px;
    white-space: pre-line;
    margin-top: 10px;

}

.arabic .answers p {
    text-align: right;
}



@media (max-width: 992px) {
    

    .accordionBlock {
        margin: 0 auto;
        justify-content: center;
    }
}


@media (max-width: 768px) {
    .frq-main-container {
        padding: 1em 0;
        margin-top: 100px;
    }
    
.accordionBlock {
    width: 100%;
    margin: 0 auto;
    justify-content: center;
}
    header .questions {
        font-size: 28px;
    }

    .question h2 {
        font-size: 1em;
    }

    .question p {
        font-size: 0.9rem;
    }

    .answers p {
        font-size: 18px;
    }

    .accordionBlock {
        width: 100%;
    }

    .sectionFourTxt {
        padding-top: 0;
        padding-bottom: 15px;
    
    
    }

   
}

@media (max-width: 480px) {
    header .questions {
        font-size: 28px;
    }


    .question h2 {
        font-size: 16px;
    }

    .question p {
        font-size: 16px;
    }

    .answers p {
        font-size: 16px;
    }

    .accordionBlock {
        width: 100%;
    }
}


@media (max-width: 340px) {
    header .questions {
        font-size: 22px;
    }
}