@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap');


.arabic {
    font-family: "Tajawal", sans-serif;
    text-align: right;
}

.english {
    font-family: "Poppins", sans-serif;
    text-align: left;
}
.footer-dark{
    background: #1a1a1a;
}

.footer {
    background: linear-gradient(101deg, #1e40af -26.52%, #00ffc2 -26.5%, #1b51b1 63.42%);
    padding-bottom: 10px;
    padding-top: 20px;
}


.footer-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer-col {
    width: 20%;
    padding: 0 15px;
}

.footer-txt {
    color: #ffffff;
}

.footer-col h4 {
    font-size: 18px;
    color: #ffffff;
    text-transform: capitalize;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 500;
}

.footer-col .img-responsive {
    margin: 20px 0;
    width: 40%;
}

.footer-col ul {
    list-style: none;
    padding: 0;
}

.footer-col ul li {
    margin-bottom: 10px;
}

.footer-col ul li a {
    font-size: 16px;
    color: #bbbbbb;
    text-decoration: none;
    transition: all 0.3s ease;
}

.footer-col ul li a:hover {
    color: #ffffff;
    padding-left: 8px;
}

.footer-col .social-links a {
    display: flex;
    flex-wrap: wrap;
    transition: all 0.5s ease;
    text-decoration: none;
    color: #bbbbbb;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 117.687%;
    padding: 10px 0;
}

.footer-col .social-links a:hover {
    color: #ffffff;
}

.styled-paragraph {
    color: white;
    text-align: center;
}

.footer-phone {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.footer-phone span {
    color: #bbbbbb;
    font-size: 16px;
}

.footer-phone span:hover {
    color: #ffffff;
}



.footer-link {
    text-decoration: none;
    font-size: 16px;
    color: #bbbbbb;
    transition: all 0.3s ease;
    cursor: pointer;
}

.footer-link:hover {
    color: #ffffff;
    padding-left: 8px;
}

.footer-col .social-links {
    display: flex;
    gap: 10px;
}

.footer-col .social-links a {
    display: inline-block;
    font-size: 24px;
    color: #bbbbbb;
    transition: color 0.3s ease;
}

.footer-col .social-links a:hover {
    color: #ffffff;
}

@media (max-width: 992px) {
    .footer-col {
        width: 50%;
        margin-bottom: 30px;
        text-align: center;
    }
    .footer-col ul,
    .footer-col .social-links {
        text-align: center;
        justify-content: center;
    }

    .footer-col .social-links {
        justify-content: center;
        margin-top: 10px;
        display: flex;
        align-items: center;
    }
    .footer-col .img-responsive {
        width: 28%;
    }
}

@media (max-width: 767px) {
    .footer {
        padding: 0;
        margin: 0;
    }

    .footer-col {
        width: 50%;
        margin-bottom: 30px;
        text-align: center;
    }
 

    .footer-col h4 {
        margin-bottom: 15px;
    }

    .footer-col ul,
    .footer-col .social-links {
        text-align: center;
        justify-content: center;
    }

    .footer-col .social-links {
        justify-content: center;
        margin-top: 10px;
        display: flex;
        align-items: center;
    }
}

@media (max-width: 574px) {
    .footer-col {
        width: 100%;
    }
}


@media (max-width: 510px) {
    .styled-paragraph {
        font-size: 12px;
    }
}


@media (max-width: 410px) {
    .styled-paragraph {
        font-size: 12px;
    }
    .footer-col h4 {
        font-size: 14px;
    }
}