@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap');


.arabic {
  text-align: right;
  font-family: "Tajawal", sans-serif;
}

.english {
  text-align: left;
  font-family: "Poppins", sans-serif;
}

.pricing-title-txt {
  color: #868D94;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 117.852%;
}

.PricingApp {
  position: relative;
  display: grid;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 375px 658px;
  z-index: 999;
  margin: 84px 0;
}






header .header-topic {
  /* color: #000; */
      color: var(--text-color);

  text-align: center;
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: 65.818px;
}


/* general */
header h1 .special-txt {
  background: linear-gradient(90deg, #045BB1  53.57%, #01C0BE 99.74%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: 65.818px;
}

header .pricing-title-txt{
  color: #868D94;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-image-container .card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

/*------------------  */



/* the center card */
.PricingCard {
  position: relative;
  padding: 2rem;
  max-width: 380px;
  display: grid;
  background-position: top right;
  background-repeat: no-repeat;
  z-index: 999;


  border-radius: 44.654px;
  /* border: 3px solid #FFF; */
  background: #FFF;

/* 1 */
box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.25);
transition: transform 0.3s ease, z-index 0.3s ease; 


}


.card-image-container {
  position: absolute;
  top:0px;
  left: 38px;
  width: 50px;
  height: 100px;
}

.card-image-container .card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-title {
  /* color: #1B1E28; */
  text-align: right;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0.5rem 0;

}

/* 100 منتج شهريا */

.card-number {
  color: #575757;
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin: 0.5rem 0;
  line-height: normal;
}

.card-price{
  text-align: center;
  /* margin-top: 15px; */
  margin: 0;
  /* color: #1B1E28; */
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.monthly-text {
  color: #575757;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.card-feature {
  margin: 0.5rem 0;
}


.card-feature-title {
  padding: 10px 0;
}

.card-btn:hover {
  background-color: #033c8a;
}

.card-features {
  margin-top: 1rem;
}



@media screen and (max-width: 992px) {

  .PricingApp .pricing-cards {
    flex-direction: column;
    gap: 2em;
  }

  .PricingCard {
    max-width: 350px;
    margin: 0 auto;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);

  }
}

@media screen and (max-width: 768px) {

  .PricingApp .pricing-cards {
    flex-direction: column;
    gap: 2em;
    margin-bottom: 20px;
  }

  .PricingCard {
    max-width: 350px;
    margin: 0 auto;
    margin-bottom: 50px;


  }
  .pricing-title-txt {
    font-size: 22px;
  }
  header h1 {
    font-size: 40px;
  }
  header .header-topic {
    font-size: 28px;
  }
}


@media (max-width: 400px) {
  .card-price{
    font-size: 30px;
  }
  
.card-title {
  font-size: 20px;

}
}

@media (max-width: 330px) {
  header .pricing-title-txt{
    font-size: 18px;
  }
}


.plan-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.plan-option {
  font-size: 18px;
  padding: 0 10px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.plan-option.active {
  font-weight: bold;
  color: #007bff; 
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin: 0 10px;
}


.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #007bff;
}
input:checked + .slider:before {
  transform: translateX(26px);
}
